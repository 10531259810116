html, body {
    min-height: 100%;
    background-color: white;
    margin: 0; /* Removes any default margin */
    padding: 0; /* Removes any default padding */
}

@keyframes rotateOnLoad {
    from {
      transform: rotate(-127deg);
    }
    to {
      transform: rotate(127deg);
    }
  }
  
  .rotate-on-load {
    animation: rotateOnLoad 3s ease-in-out forwards; /* Adjust duration as needed */
  }
  